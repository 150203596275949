/* eslint-disable */
import React from "react";

const PageNotFound = () => {
    return (
        <div className="text-center">
            <h1>404 ERROR</h1>
            <h1>KHÔNG TÌM THẤY TRANG CỦA BẠN, VUI LÒNG QUAY VỀ TRANG CHỦ</h1>
        </div>
    );
};

export default PageNotFound;